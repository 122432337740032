<template>
  <div class="Terminos">

<v-container>
<v-row  class="card-poly">

 <v-col cols="12" md="12" lg="12">

   
      <div class="col-md-12 col-terms">
        <div class="terms-title">
          <h1 class="color-primary">TÉRMINOS Y CONDICIONES</h1>
          <h1 class="color-primary" v-if="basa">Cupón BASA</h1>
          <h1 class="color-primary" v-if="superBowl">Cupón Super Bowl</h1>
          <h1 class="color-primary" v-if="freeDelivery">Cupón Free Delivery</h1>
        </div>

        <div class="terms-sub-title">
          <p>El operador del SITIO WEB y el APP</p>
        </div>
        <div class="terms-details">
          <p class="depto-details">El SITIO WEB y el APP son operados por OCHO Corporation.
          </p>
        </div>
        <div class="terms-details">
          <p class="depto-details">Última actualización: 7 de Febrero 2023
          </p>
        </div>

<p>Por medio del presente se dan a conocer los términos y condiciones generales aplicables por la promoción de cupón  
  <span v-if="basa">BASA</span>
  <span v-if="superBowl">Super Bowl</span>
  <span v-if="freeDelivery">Free Delivery</span>, que se llevará a cabo el 
   
  <span v-if="basa">10</span>
  <span v-if="superBowl">12</span>
  <span v-if="freeDelivery">14</span>
  
  
  de febrero del 2023 en ocho App en convenio con Banco Atlántida, con sede en la Ciudad de Tegucigalpa.

</p>
<br>
<!-- <p><b>Se deberá cumplir con los siguientes requisitos:
</b></p> -->
<div class="BASA" v-if="basa">

<p><b>Cupón BASA 10 de febrero:
</b></p>

<p >
<ul>
  <li>
   Aplican para todas las ciudades donde tiene presencia OCHO.
  </li>
  <li>
   Aplican para tarjetas de Crédito y Débito Personales Atlántida.
  </li>
  <li>
   No aplica para tarjetas Pymes, Empresariales ni Puma flota. 
  </li>
  <li>
   Recibe un cupón de L.110.00 en tus compras de restaurantes y bebidas.
  </li>
  <li>
   Aplica por consumos mayores o iguales a L.300.00
  </li>
  <li>
   Aplica al cupón utilizando el código BANCATLAN.
  </li>
  <li>
   Aplica a las primeras 110 personas.
  </li>
  <li>
   Aplica en el horario de 11:00 am hasta agotar existencia. 
  </li>
  <li>
  El cliente al momento de realizar la compra debera utilizar cupón BANCATLAN.
  </li>
  <li>
  Promoción vigente del 10 al 10 de febrero 2023.
  </li>
  <li>
   El bono es asumido por Banco Atlántida.
  </li>
</ul>


</p>
<br>
</div>

<div class="superBowl" v-if="superBowl">

<p><b>Super Bowl 12 de febrero:
</b></p>

<p>
  <ul>
  <li>
   Aplican para todas las ciudades donde tiene presencia OCHO.
  </li>
  <li>
  Aplican para tarjetas de Crédito y Débito Personales Atlántida.
  </li>
  <li>
 No aplica para tarjetas Pymes, Empresariales ni Puma flota. 
  </li>
  <li>
 Recibe un cupón de L.100.00 en tus compras de restaurantes y bebidas.
  </li>
  <li>
 Aplica a las primeras 70 personas.
  </li>
  <li>
 Aplica en el horario de 5:00 PM A 10:00 PM.
  </li>
  <li>
 El cliente al momento de realizar la compra debe utilizar cupón BANCATLAN.
  </li>
  <li>
 Promoción vigente para el 12 de febrero 2023.
  </li>
  <li>
 El cupón es asumido por Banco Atlántida.
  </li>
  <li>
 Aplica por compras iguales o mayores a L300.00
  </li>
</ul>
</p>


</div>


<div class="freeDelivery" v-if="freeDelivery">
<br>

<p><b>Free Delivery 14 de febrero:
</b></p>

<p>
  <ul>
  <li>
   Aplican para todas las ciudades donde tiene presencia OCHO.
  </li>
  <li>
  Aplican para tarjetas de Crédito y Débito Personales Atlántida.
  </li>
  <li>
 No aplica para tarjetas Pymes, Empresariales ni Puma flota.
  </li>
  <li>
 Recibe Free Delivery por tus compras de restaurantes, bebidas y OCHOSHOP.
  </li>
  <li>
 Promoción vigente el 14 de febrero 2023.
  </li>
  <li>
 El Free Delivery lo asume Banco Atlántida.
  </li>
  <li>
Aplica utilizando el código BANCATLAN.
  </li>
  <li>
 El delivery por cliente es de uno.
  </li>
</ul>
</p>



</div>

</div>

</v-col>

</v-row>


</v-container>




  </div>
</template>
<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
export default {
  name: "Terminos",
  components: {},
  data() {
    return {
      basa: false,
      superBowl: false,
      freeDelivery: false,
      toDay: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    };
  },
  mounted: function () {
    window.scrollTo(0, 0);

    if (this.toDay <= "2023-02-14") {
      this.basa = false;
      this.superBowl = false;
      this.freeDelivery = true;
    } else {
      this.freeDelivery = true;
    }
    if (this.toDay <= "2023-02-12") {
      this.basa = false;
      this.superBowl = true;
      this.freeDelivery = false;
    }
    if (this.toDay <= "2023-02-10") {
      this.basa = true;
      this.superBowl = false;
      this.freeDelivery = false;
    }

    // gsap.to('.phones', {x: 500, duration: 3})
  },
};
</script>
<style  src="@/main.scss" lang="scss"></style>
<style lang="scss" >
@import "@/_responsive.scss";
.Terminos {
  width: 100%;
  padding-top: 150px;
  padding-bottom: 150px;
  background-image: url("../assets/banners-poly.jpg");
  background-size: contain;
  height: auto;
  position: relative;
  background-color: rgb(255, 255, 255);
}
.terms-title {
  font-family: "Poppis-bold";
  font-size: 30px;
}
.terms-sub-title {
  p {
    font-family: "poppins-bold";
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
.card-poly {
  background-color: #fff;
  border-radius: 1em;
  box-shadow: #2c2c2c3d 0px 10px 30px;
  padding: 2em;
}
</style>